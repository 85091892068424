import styles from './Popup.module.scss';
import Close from '../Icons/Close';
import { dialogData, openDialog } from '#store/openDialog';
import { useStore } from '@nanostores/react';
import QuotesIcon from '#components/ui/Icons/Quotes';
import Rating from '#components/ui/Rating';
import { useRef, useState } from 'react';
import useClickOutside from '#hooks/useClickOutside';

const Popup = () => {
  const $openDialog = useStore(openDialog);
  const $dialogData = useStore(dialogData);
  const [open, setIsOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleCloseDialog = () => {
    openDialog.set(false);
    setIsOpen(!open);
  };

  const ReviewData = () => {
    useClickOutside(popupRef, () => {
      if ($openDialog) setIsOpen(!open);
      if (open) handleCloseDialog();
    });

    return (
      <div className={styles.card} ref={popupRef}>
        <div className={styles.titleWrapper}>
          <QuotesIcon />
          <h3 className={styles.authorName}>{$dialogData.author}</h3>
        </div>
        <div>
          <Rating rating={$dialogData.rating} />
        </div>
        <div className={styles.content}>{$dialogData.content}</div>
      </div>
    );
  };
  return (
    $openDialog && (
      <section className={styles.popup}>
        <div className={styles.popupWrapper}>
          <div className={styles.popupContainer}>
            <button className={styles.popupClose} onClick={() => handleCloseDialog()}>
              <Close />
            </button>
            <ReviewData />
          </div>
        </div>
      </section>
    )
  );
};

export default Popup;
